import React from 'react';
import './SidebarItem.css';
import { Link } from 'react-router-dom';

const sidebarItem = (props) => {
    return (
        <div className="SidebarItem">
            <Link to={`/campaign/${props.offerId}`}>
                <div className="SidebarItem-img border rounded" style={{ backgroundImage: `URL(${props.thumbnail}` }}>
                </div>
            </Link>
            <div style={{ fontSize: "0.85rem", paddingLeft: ".8rem", textAlign: "start" }}>
                <div style={{ fontWeight: "500", textAlign: "start" }}><Link className="text-dark" to={`/campaign/${props.offerId}`}>{props.name}</Link></div>
                <div className="campaign-rate" >
                    {props.rateType === '$ per lead' ? <span>${parseFloat(props.rate).toFixed(2)} per lead</span> : <span>{props.rate}% of sale</span>}
                </div>
            </div>
        </div>);
}

export default sidebarItem;