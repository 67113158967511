import React, { Component } from 'react';
import TrafficTypeItem from '../CampaignPage/TrafficTypeItem/TrafficTypeItem';

import { clearLocalStorageAndReload, verifyToken } from '../../assets/JS/functions';

import mbStacked from '../../assets/images/maxbounty_stacked.png';
import mbHorizontal from '../../assets/images/MaxBounty_Horizontal.png';


class Refer extends Component {
    state = {}

    componentDidMount() {

        verifyToken().then(result => {
            if (result !== true) {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            this.props.showSystemMessage('error', error.message);
        });
    }

    render() {
        return (
            <div className="py-2">
                <div className="split-left">
                    <div className="card">
                        <div className="card-header d-flex align-items-center">
                            Refer an Affiliate
                        </div>
                        <div className="card-body">
                            <h4>MaxBounty Affiliate Referral Program</h4>
                            <hr></hr>
                            <p>Refer other affiliates into MaxBounty and earn 5% of their revenue for 12 months.</p>
                            <p>We've found the most effective method of using the referral program is through personal reviews and recommendations. Just putting a banner up on a page isn't very effective at generating sign ups. For that reason, we no longer provide MaxBounty branded creatives. We give you a link and some logos, but the rest of the content is up to you. Here are some ideas:</p>
                            <ul>
                                <li>Put a small link in the footer of your website saying "Earn money with your website"</li>
                                <li>Link to us in the signature line of your favorite affiliate message board</li>
                                <li>Write a full review of your take on the benefits of working with MaxBounty</li>
                                <li>Blog about your campaign successes, mentioning they're specifically from MaxBounty</li>
                            </ul>
                            <p>There are a multitude of details you can provide to other potential publishers in your review of our network: Our weekly payments, and the fact that they are never late. How we work with international publishers. We have over 20,000 active affiliates. There are over 1,500 campaigns to advertise across all traffic types like surveys, freebies, financial, insurance, dating, diet, etc. Describe the benefits of having a responsive Affiliate Manager whom you can reach through phone, IM or email.</p>
                            <p>Terms regarding the affiliate referral program can be found in the general affiliates terms and conditions.</p>

                            <hr></hr>

                            <strong>Allowed Traffic Types: </strong>
                            <TrafficTypeItem key="Email" trafficType="email_traffic" allowed="N" type="description" />
                            <TrafficTypeItem key="Contextual" trafficType="contextual_traffic" allowed="Y" type="description" />
                            <TrafficTypeItem key="Display" trafficType="display_traffic" allowed="Y" type="description" />
                            <TrafficTypeItem key="Search" trafficType="search_traffic" allowed="Y" type="description" />
                            <TrafficTypeItem key="Incentive" trafficType="incentive_traffic" allowed="Y" type="description" />
                            <TrafficTypeItem key="Social" trafficType="social_media_traffic" allowed="Y" type="description" />
                            <TrafficTypeItem key="Native" trafficType="native_traffic" allowed="Y" type="description" />
                            <TrafficTypeItem key="Mobile Ads" trafficType="mobile_traffic" allowed="Y" type="description" />

                            <hr></hr>

                            <strong>Countries Allowed: </strong>
                            Traffic from all countries allowed.

                            <hr></hr>

                            <div className="d-flex align-items-center">
                                <div><strong>Your referral URL: </strong></div>
                                <div className="alert alert-success mb-0 ml-2 p-2">
                                    {`${process.env.REACT_APP_PORTAL_URL}/register?referrer=${this.props.affiliate.affiliate_id}`}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="card my-2">
                        <div className="card-header d-flex align-items-center">
                            Logos
                        </div>
                        <div className="card-body">
                            <p>Right click + "Save Image As" to save a copy of the MaxBounty logo.</p>
                            <hr></hr>
                            <div>
                                1. Stacked MaxBounty Logo:<br></br>
                                <img src={mbStacked} alt="MaxBounty Stacked Logo" style={{ width: '100%', maxWidth: '250px' }} />
                            </div>
                            <div className="mt-3">
                                2. Horizontal MaxBounty Logo:<br></br>
                                <img src={mbHorizontal} alt="MaxBounty Horizontal Logo" style={{ width: '100%', maxWidth: '500px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Refer;