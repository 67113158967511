import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import maxbountyLogoSquare from '../../assets/images/MaxBounty_Square.png';
import dotPattern from '../../assets/images/dot-pattern.png';
import loginVideo from '../../assets/images/login.mp4';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const recaptchaRef = React.createRef();
var captchaValid =false;

class ForgotPassword extends Component {

    state = { 
        dataLoaded: true,
        response: false
    }    

    handleSubmit = (e) => {
        e.preventDefault();

        const email = e.target.email.value;        
        
        /* Check if the captcha was validated */
        if (captchaValid){

            this.setState({
                dataLoaded: false,
                response: false
            })

            /* Submit the request to the back end, which will spit out a token if the email is valid. */
            fetch(`${process.env.REACT_APP_API_URL}/forgotPassword?email=${email}`, {
                method: 'GET',
                headers:{
                    'Content-Type': 'application/json',
                }                
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        
                        if (result["success"] === true){
                            this.props.showSystemMessage("success", `We have sent an e-mail to ${email} with instructions on how proceed.`);      
                        } else{
                            this.props.showSystemMessage("error", result.errors.message);                            
                        }

                        this.setState({
                            dataLoaded:true,
                            response: true
                        })
                                           
                    },
                    (error) => {
                        this.props.showSystemMessage("error", "Connection error.")
                        this.setState({
                            dataLoaded:true,
                            response: true
                        })
                    }
                )           

        }
        
    }

    handleCaptcha = () => {
        /* The captcha was successful if this method fires */        
        captchaValid = true;        
    }

    render() { 
        return ( 
            <React.Fragment>

                <section className="login-container text-center d-flex align-items-center" style={{background:'RGBA(0,0,0,0.9)'}}>

                    {/* Background Video */}
                    <div style={{backgroundImage:`URL(${dotPattern})`, backgroundRepeat:'repeat', height:'100%', width:'100%', position:'absolute', zIndex:'9'}}></div>
                    <video autoPlay muted loop className="login-video">
                        <source src={loginVideo} type="video/mp4"/>
                    </video>

                        <form
                        className="form-signin"
                        ref={this.form}
                        onSubmit={this.handleSubmit}>
                            <img className="mb-4 rounded" src={maxbountyLogoSquare} alt="MaxBounty" width="100"/>
                            <p className="font-italic text-white pb-2">Enter your e-mail here and we will provide instructions on how to reset your password.</p>
                            <label htmlFor="inputEmail" className="sr-only">Email address</label>
                            { this.state.submitted ?
                                <input className="form-control mb-3" type="email" style={{borderRadius:'4px'}} placeholder="Submitted" disabled />
                            :
                                <input
                                    ref={this.email}
                                    name="email"
                                    id="inputEmail"
                                    className="form-control mb-3"
                                    placeholder="Email address"
                                    autoFocus=""
                                    type="email"
                                    required
                                    style={{borderRadius:'4px'}}/>
                            }                            
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                className="mb-3"
                                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                                onChange={this.handleCaptcha} />
                            { this.state.dataLoaded ?
                                <React.Fragment>
                                    { this.state.submitted ? 
                                        <button onClick={this.login} className="btn btn-lg btn-secondary btn-block" disabled>Submit</button>
                                    :
                                        <button onClick={this.login} className="btn btn-lg btn-primary btn-block">Submit</button>
                                    }                                    
                                </React.Fragment>
                            :
                                <button className="btn btn-lg btn-primary btn-block" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                            }                            
                            <Link to="/">Back to Login</Link><br></br>
                            <p className="mt-5 mb-3 text-muted">MaxBounty Inc. </p>
                        </form>

                </section>
                        
            </React.Fragment>
        );
    }
}
 
export default ForgotPassword;