import React, { Component } from 'react';

import { Collapse } from 'reactstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import AdvanceSearch from './AdvanceSearch/AdvanceSearch';
import SearchResults from './SearchResults/SearchResults';
import Loading from '../../component/UI/Loading/Loading';

import { FaExclamationTriangle, FaMoneyBill, FaEnvelope, FaWindowRestore, FaMobile, FaFacebook, FaImage, FaSearch, FaDesktop, FaGavel } from 'react-icons/fa';
import { ChevronUp, ChevronDown } from 'react-feather';
import ReactTooltip from 'react-tooltip';
import Pagination from '../../component/UI/Pagination/Pagination';

import './Search.css';
import { clearLocalStorageAndReload, verifyToken } from '../../assets/JS/functions';
import AutoCompleteList from '../../component/Navigation/Toolbar/SearchCampaign/AutoCompleteList/AutoCompleteList';

class Search extends Component {

    state = {
        showAdvanceSearch: false,
        showAutocompleteList: false,
        searchBarText: '', // If user navigates to Search page by typing something in SearchCampaigns options, this should be loaded. If not, load placeholder
        searchResults: [],
        matchingCampaigns: [],
        searchCheckboxes: { // Might be possible to remove detailed states, can just be assigned the ones received from child component
            ep: true,   // Email
            cp: true,   // Contextual
            bp: true,   // Display / Banner
            sp: true,   // Search
            ip: true,   // Incentive
            lp: true,   // Social Media
            np: true,   // Native  
            mp: true,   // Mobile
            bbp: true,  // Brand Bidding
            af: false,  // Available Filter
            ff: false,   // Favorite Filter / Bookmark
            rf: false
        },
        gr: "",
        grstrict: 0,
        trafficstrict: 0,
        category: 0,
        subcategory: 0,
        cpaType: "",
        dataLoaded: false,
        autocompleteLoaded: false,
        filtersLoaded: false,
        setSearchFilters: "",
        page: 1,
        totalPages: 1,
        orderBy: '',
        ascdesc: 'd',
        finalURL: '?st=&ob=date&ep=true&cp=true&bp=true&sp=true&ip=true&lp=true&np=true&mp=true&bbp=true&tstrict=0&gr=&grstrict=0&m=0&c=0&af=false&ff=false&rf=false&as=false&ct='
    }

    // Toggles advanced search options
    advanceSearchToggleHandler = () => {
        this.setState({ showAdvanceSearch: !this.state.showAdvanceSearch });
    }

    // When quickSearchBar is used from search page itself (i.e; the url is '/search')
    componentDidUpdate(prevProps, prevState) {

        if (prevState.finalURL !== this.state.finalURL) {
            this.setState({ ...this.state, finalURL: this.state.finalURL }, () => {
                this.fetchAdvancedSearchData();
            });
        } else if (this.props.location.state) { // When searching in quick search bar while being on Search page itself

            let quickSearchQuery = this.props.location.state.quickSearchQuery.trim();

            if (quickSearchQuery !== this.state.searchBarText) {
                this.setState({ searchBarText: quickSearchQuery, finalURL: `?st=${quickSearchQuery}&ob=date&ep=true&cp=true&bp=true&sp=true&ip=true&lp=true&np=true&mp=true&bbp=true&tstrict=0&gr=&grstrict=0&m=0&c=0&af=false&ff=false&rf=false&as=false&ct=&pn=1` });
            }
        }
    }

    // Pagination - show next page of results
    handleResultPageChange = (requestedPage = this.state.page) => {
        let page = requestedPage;
        if (page !== this.state.page) {
            const url = `?st=${this.state.searchBarText}&ep=${this.state.searchCheckboxes.ep}&cp=${this.state.searchCheckboxes.cp}&bp=${this.state.searchCheckboxes.bp}&sp=${this.state.searchCheckboxes.sp}&ip=${this.state.searchCheckboxes.ip}&lp=${this.state.searchCheckboxes.lp}&np=${this.state.searchCheckboxes.np}&mp=${this.state.searchCheckboxes.mp}&bbp=${this.state.searchCheckboxes.bbp}&tstrict=${this.state.trafficstrict}&gr=${this.state.gr}&grstrict=${this.state.grstrict}&m=${this.state.category}&c=${this.state.subcategory}&af=${this.state.searchCheckboxes.af}&ff=${this.state.searchCheckboxes.ff}&rf=${this.state.searchCheckboxes.rf}&as=${this.state.showAdvanceSearch}&ct=${this.state.cpaType}&ob=${this.state.orderBy}&pn=${page}&ad=${this.state.ascdesc}`;

            this.setState({ ...this.state, dataLoaded: false, page: page, finalURL: url }, () => {
                this.props.history.push(`/search${this.state.finalURL}`);
            });
        }
    }

    componentDidMount() {
        // When searched in quick search bar and submitted
        if (this.props.location.state) {
            this.setState({ searchBarText: this.props.location.state.quickSearchQuery.trim(), finalURL: `?st=${this.props.location.state.quickSearchQuery}&ob=date&ep=true&cp=true&bp=true&sp=true&ip=true&lp=true&np=true&mp=true&bbp=true&tstrict=0&gr=&grstrict=0&m=0&c=0&af=false&ff=false&rf=false&as=false&ct=&pn=1` }, () => {
                // this.fetchAdvancedSearchData();
            });
        }
        // Refreshing or clicking back to the page
        else if (this.props.location.search) {
            let urlParams = queryString.parse(this.props.location.search);
            let page;

            if (urlParams.pn) {
                page = parseInt(urlParams.pn);
            } else {
                page = 1;
            }

            this.setState({ finalURL: this.props.location.search, searchBarText: urlParams.st, searchCheckboxes: { ...this.state.searchCheckboxes, ep: JSON.parse(urlParams.ep), cp: JSON.parse(urlParams.cp), bp: JSON.parse(urlParams.bp), lp: JSON.parse(urlParams.lp), ip: JSON.parse(urlParams.ip), mp: JSON.parse(urlParams.mp), np: JSON.parse(urlParams.np), sp: JSON.parse(urlParams.sp), af: JSON.parse(urlParams.af), ff: JSON.parse(urlParams.ff), rf: JSON.parse(urlParams.rf), bbp: JSON.parse(urlParams.bbp) }, grstrict: JSON.parse(urlParams.grstrict), trafficstrict: JSON.parse(urlParams.tstrict), category: JSON.parse(urlParams.m), subcategory: JSON.parse(urlParams.c), gr: urlParams.gr, cpaType: urlParams.ct, page: page, ascdesc: urlParams.ad, orderBy: urlParams.ob, showAdvanceSearch: JSON.parse(urlParams.as) }, () => {
                // this.fetchAdvancedSearchData(); // Removed while fixing chevron issue. Was making double calls so.
                this.getSearchOptions().catch(error => error);
            });
        }
        else {    // If user navigates to '/search' directly, default search should run with empty search string  
            this.fetchAdvancedSearchData();
            this.setState({ showAdvanceSearch: true });
        }
    }

    // Setting state for checkbox data when form is submitted
    searchCheckboxHandler = (checkboxes) => {
        this.setState({
            searchCheckboxes: checkboxes
        });
    }

    // Called when search is clicked through advanced search options
    applySearchOptionsHandler = (event) => {

        let ob;

        // If user has already clicked for sorting method by clicking on headers, keep it or else get it from form submission which is 'date' default
        // ascdesc is reset after clicking search

        if (this.state.orderBy) {
            ob = this.state.orderBy;
        } else {
            ob = event.target.ob.value;
        }

        const url = `?st=${(event.target.st.value).trim()}&ob=${ob}&ep=${this.state.searchCheckboxes.ep}&cp=${this.state.searchCheckboxes.cp}&bp=${this.state.searchCheckboxes.bp}&sp=${this.state.searchCheckboxes.sp}&ip=${this.state.searchCheckboxes.ip}&lp=${this.state.searchCheckboxes.lp}&np=${this.state.searchCheckboxes.np}&mp=${this.state.searchCheckboxes.mp}&bbp=${this.state.searchCheckboxes.bbp}&tstrict=${event.target.trafficstrict.value}&gr=${event.target.gr.value}&grstrict=${event.target.grstrict.value}&m=${event.target.m.value}&c=${event.target.c.value}&af=${this.state.searchCheckboxes.af}&ff=${this.state.searchCheckboxes.ff}&rf=${this.state.searchCheckboxes.rf}&as=${this.state.showAdvanceSearch}&ct=${event.target.ct.value}&pn=1`;

        this.props.history.push(`/search${url}`);

        this.setState({ ...this.state, finalURL: url, gr: event.target.gr.value, category: event.target.m.value, subcategory: event.target.c.value, cpaType: event.target.ct.value, searchBarText: event.target.st.value, searchResults: [], dataLoaded: false, page: 1 }, () => {
            this.getSearchOptions().catch(error => console.log(error));
            this.fetchAdvancedSearchData();
        });
        event.preventDefault();
    }

    // Making a call to the API to fetch search results
    fetchAdvancedSearchData = () => {

        const authtoken = localStorage.getItem('mb-auth-token');

        verifyToken().then(result => {
            if (result === true) {
                fetch(`${process.env.REACT_APP_API_URL}/search${this.state.finalURL}`, {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-app-token': authtoken
                    }
                })
                    .then(res => res.json())
                    .then(result => {
                        if (result["success"] === true) {
                            this.setState({
                                searchResults: result["matchingCampaigns"], totalPages: result["total_pages"],
                                dataLoaded: true
                            });
                        } else if (result["success"] === false) {
                            if (result.code !== 401) {
                                this.setState({ dataLoaded: true, searchResults: [] });
                            }
                        } else {
                            this.props.showSystemMessage("error", "There was an error while fetching search results.");
                        }
                    });
            } else {
                clearLocalStorageAndReload();
            }
        }).catch(error => {
            this.props.showSystemMessage("error", error.message);
            this.setState({ dataLoaded: true });
        });
    }

    // Fetch country, maincategory, subcategory names to show in selected searchOptions

    fetchCountryName = async () => {
        let mbauthtoken = localStorage.getItem('mb-auth-token');
        try {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/display/country/${this.state.gr}`, {
                method: "GET",
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    "x-app-token": mbauthtoken
                }
            });

            if (!response.ok) {
                throw Error(response.statusText);
            }

            let result = await response.json();

            if (result["success"] === true) {
                return result["country_name"];
            } else if (result["success"] === false) {
                if (result.code === 401) {
                    clearLocalStorageAndReload();
                } else {
                    this.props.showSystemMessage("error", result.message);
                }
                return;
            } else {
                this.props.showSystemMessage("error", result.message)
            }

        } catch (error) {
            this.props.showSystemMessage("error", error.message);
            return;
        }
    }

    fetchCategoryName = async () => {
        let mbauthtoken = localStorage.getItem('mb-auth-token');

        try {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/display/maincategory/${this.state.category}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': mbauthtoken
                }
            });

            if (!response.ok) {
                throw Error(response.statusText);
            }

            let result = await response.json();

            if (result["success"] === true) {
                return result["main_category_name"];
            } else if (result["success"] === false) {
                if (result.code === 401) {
                    clearLocalStorageAndReload();
                } else {
                    this.props.showSystemMessage("error", result["message"]);
                }
                return;
            } else {
                this.props.showSystemMessage("error", result.message);
            }
        } catch (error) {
            this.props.showSystemMessage("error", error.message);
            return;
        }
    }

    fetchSubCategoryName = async () => {
        let mbauthtoken = localStorage.getItem('mb-auth-token');

        try {
            let response = await fetch(`${process.env.REACT_APP_API_URL}/display/subcategory/${this.state.category}/${this.state.subcategory}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': mbauthtoken
                }
            });

            if (!response.ok) {
                throw Error(response.statusText);
            }

            let result = await response.json();

            if (result["success"] === true) {
                return result["subcategory_name"];
            } else if (result["success"] === false) {
                if (result.code === 401) {
                    clearLocalStorageAndReload();
                } else {
                    this.props.showSystemMessage("error", result["message"]);
                }
            }
        } catch (error) {
            this.props.showSystemMessage("error", error.message);
        }
    }

    getSearchOptions = async () => {
        let searchOptions = ``;

        // Set searchOptions
        if (this.state.gr === "" && parseInt(this.state.category) === 0 && parseInt(this.state.subcategory) === 0 && this.state.cpaType === "" && this.state.searchCheckboxes.ip && this.state.searchCheckboxes.bp && this.state.searchCheckboxes.sp && this.state.searchCheckboxes.ep && this.state.searchCheckboxes.mp && this.state.searchCheckboxes.np && this.state.searchCheckboxes.cp && this.state.searchCheckboxes.lp && this.state.searchCheckboxes.bbp &&
            !this.state.searchCheckboxes.ff && !this.state.searchCheckboxes.af && !this.state.searchCheckboxes.rf) {
            searchOptions = ``;
        } else {

            if (this.state.searchCheckboxes.ip && this.state.searchCheckboxes.bp && this.state.searchCheckboxes.sp && this.state.searchCheckboxes.ep && this.state.searchCheckboxes.mp && this.state.searchCheckboxes.np && this.state.searchCheckboxes.cp && this.state.searchCheckboxes.lp && this.state.searchCheckboxes.bbp) {
                searchOptions = '';
            } else {
                if (this.state.searchCheckboxes.ep) {
                    searchOptions += " Email /";
                }
                if (this.state.searchCheckboxes.cp) {
                    searchOptions += " Contextual /";
                }
                if (this.state.searchCheckboxes.bp) {
                    searchOptions += " Display /";
                }
                if (this.state.searchCheckboxes.sp) {
                    searchOptions += " Search /";
                }
                if (this.state.searchCheckboxes.ip) {
                    searchOptions += " Incentive /";
                }
                if (this.state.searchCheckboxes.lp) {
                    searchOptions += " Social Media /";
                }
                if (this.state.searchCheckboxes.np) {
                    searchOptions += " Native /";
                }
                if (this.state.searchCheckboxes.mp) {
                    searchOptions += " Mobile /";
                }
                if (this.state.searchCheckboxes.bbp) {
                    searchOptions += " Brand Bidding /";
                }
            }

            // Check if country is selected
            if (this.state.gr !== "") {
                if (this.state.gr === "ALL") {
                    searchOptions += ` Allow all countries / `;
                } else {
                    let tempCountry = await this.fetchCountryName();
                    searchOptions += ` ${tempCountry} /`;
                }
            }

            // Check if category is set
            if (parseInt(this.state.category) !== 0) {

                let tempCategory = await this.fetchCategoryName();
                searchOptions += ` ${tempCategory}`;

                if (parseInt(this.state.subcategory) !== 0) {

                    searchOptions += " - ";
                    let tempSubcategory = await this.fetchSubCategoryName();
                    searchOptions += `${tempSubcategory}  / `;
                } else {
                    searchOptions += ' / ';
                }
            }

            // If CPA type is set
            if (this.state.cpaType !== "") {
                if (this.state.cpaType === 'P') {
                    searchOptions += " % of sale /";
                } else {
                    searchOptions += " $ per lead /";
                }
            }

            if (this.state.searchCheckboxes.ff) {
                searchOptions += " Bookmarked /";
            }

            if (this.state.searchCheckboxes.af) {
                searchOptions += " My Approved Offers /";
            }

            if (this.state.searchCheckboxes.rf) {
                searchOptions += " Never Run Before /";
            }
        }

        if (searchOptions === "") {
            this.setState({ filtersLoaded: false, setSearchFilters: searchOptions });
        } else {
            this.setState({ filtersLoaded: true, setSearchFilters: searchOptions });
        }
    }

    // Whenever a new URL is needed from state values
    buildURL = () => {
        let url = `?st=${this.state.searchBarText}&gr=${this.state.gr}&grstrict=${this.state.grstrict}&c=${this.state.subcategory}&m=${this.state.category}&ct=${this.state.cpaType}&sp=${this.state.searchCheckboxes.sp}&ep=${this.state.searchCheckboxes.ep}&ip=${this.state.searchCheckboxes.ip}&cp=${this.state.searchCheckboxes.cp}&lp=${this.state.searchCheckboxes.lp}&bp=${this.state.searchCheckboxes.bp}&mp=${this.state.searchCheckboxes.mp}&bbp=${this.state.searchCheckboxes.bbp}&np=${this.state.searchCheckboxes.np}&tstrict=${this.state.trafficstrict}&af=${this.state.searchCheckboxes.af}&ff=${this.state.searchCheckboxes.ff}&rf=${this.state.searchCheckboxes.rf}&as=${this.state.showAdvanceSearch}&ob=${this.state.orderBy}`;

        if (this.state.page !== 1) {
            url += `&pn=${this.state.page}`;
        }

        return url;
    }

    // Function to buildURL, add asc/desc to the url
    sortingHelper = () => {
        let url = this.buildURL();
        let tempAD;

        if (this.state.ascdesc === 'a' || !this.state.ascdesc) {
            tempAD = 'd';
            url += `&ad=${tempAD}`;
        } else {
            tempAD = 'a';
            url += `&ad=${tempAD}`;
        }

        this.setState({ dataLoaded: false, searchResults: [], finalURL: url, ascdesc: tempAD }, () => {
            this.props.history.push(`/search${this.state.finalURL}`);
        });
    }

    // Sorting the results by ID, Name, Rate, EPC

    orderById = () => {
        this.setState({ orderBy: 'id' }, () => {
            this.sortingHelper();
        });
    }

    orderByName = () => {
        this.setState({ orderBy: 'name' }, () => {
            this.sortingHelper();
        });
    }

    orderByRate = () => {
        this.setState({ orderBy: 'rate' }, () => {
            this.sortingHelper();
        });
    }

    orderByEPC = () => {
        this.setState({ orderBy: 'epc' }, () => {
            this.sortingHelper();
        });
    }

    sortChevron = (sortBy) => {
        if (sortBy === this.state.orderBy) {
            if (this.state.ascdesc === 'a') {
                return <ChevronUp size={20} />;
            } else if (this.state.ascdesc === 'd') {
                return <ChevronDown size={20} />;
            }
        } else {
            return null;
        }
    }

    // Dismiss autocomplete list 
    toggle = () => {
        this.setState({ showAutocompleteList: false });
    }

    searchInputHandler = (event) => {
        let inputValue = (event.target.value);

        if (inputValue.trim()) {
            this.setState({ ...this.state, searchBarText: inputValue }, () => {
                this.getQuickSearchData(this.state.searchBarText.trim());
            });
        } else {
            this.setState({ showAutocompleteList: false, searchBarText: '', autocompleteLoaded: false });
        }
    }

    getQuickSearchData = (searchText) => {
        const authToken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/search?q=${searchText}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    this.setState({
                        autocompleteLoaded: true,
                        showAutocompleteList: true,
                        matchingCampaigns: result["matchingCampaigns"]
                    });
                } else if (result["success"] === false) {
                    this.setState({ autocompleteLoaded: true, showAutocompleteList: false })
                } else {
                    this.props.showSystemMessage("error", "There was an error while fetching quick search results.");
                    this.setState({
                        dataLoaded: true,
                        showAutocompleteList: false
                    })
                }
            });
    }


    listClickHandler = (event) => {
        let name = event.target.getAttribute('name');
        this.setState({ ...this.state, searchBarText: name, showAutocompleteList: false });
    }

    render() {

        let autocompleteClasses = "noshow";

        if (this.state.showAutocompleteList) {
            autocompleteClasses = "ui-autocomplete";
        }

        let list;

        if (this.state.showAutocompleteList === true && this.state.autocompleteLoaded === true) {
            list = this.state.matchingCampaigns.map(match =>
                <AutoCompleteList
                    key={match.campaign_id}
                    offerId={match.campaign_id}
                    name={match.name}
                    listClicked={this.listClickHandler}
                />
            )
        }

        return (
            <React.Fragment>
                <div className="Search mb-2">
                    <div className="d-flex flex-wrap rounded-bottom" style={{ backgroundColor: "#f4f4f4" }}>

                        <form className="flex-fill" method="get" onSubmit={this.applySearchOptionsHandler} action=''>
                            <div className="row d-flex flex-row align-items-center py-2 ">
                                <div className="col-sm-2 font-weight-bold">
                                    Searching For:
                                </div>

                                <div className="col-sm-8">
                                    <input className="form-control border-0" type="text" name="st" placeholder="Enter a campaign name, keyword or description" style={{ fontSize: "14px" }}
                                        onChange={this.searchInputHandler}
                                        autoComplete="off"
                                        value={this.state.searchBarText}
                                    />
                                    <input name="ob" value="date" type="hidden" />

                                    <ul className={autocompleteClasses} id="ui-id-1" tabIndex="0" >
                                        {list}
                                    </ul>
                                </div>

                                {/* change class for advance search to not scroll when list is up using state that show List */}

                                <div className="col-sm-2">
                                    <button type="button" className="btn btn-link btn-sm p-0" onClick={this.advanceSearchToggleHandler}>
                                        Search Options
                                    </button>
                                </div>
                            </div>
                            <div>
                                <Collapse isOpen={this.state.showAdvanceSearch}>
                                    <AdvanceSearch showSystemMessage={this.props.showSystemMessage}
                                        checkboxSelected={this.searchCheckboxHandler}
                                    />
                                </Collapse>
                            </div>
                            {this.state.showAdvanceSearch ? <div className="text-center pb-2" style={{ backgroundColor: "white" }}>
                                <input type="submit" className="btn btn-primary" value="Apply Search Options" />

                            </div> : null}
                        </form>
                    </div>
                </div>

                {/* Show which types, details, filters are set, if any:
                
                    geo_retsriction, mainCategory, subCategory, cpaType, incentivePerm, bannerPerm, searchPerm, emailPerm, mobilePerm, nativePerm, contextualPerm, socialPerm, mobileFilter, favFilter, sugFilter, availableFilter
                            
                */}

                {/* show this section only if results are present */}

                {/* Load results only when dataLoaded is true */}

                {this.state.dataLoaded
                    ? (this.state.searchResults.length !== 0
                        ? <div>
                            {(this.state.filtersLoaded) ?
                                (<div className="d-flex rounded mt-2" style={{ background: "white", marginBottom: "10px" }}>
                                    <span className="font-weight-bold px-3 py-2" style={{ borderBottom: "3px solid #0089FE" }}>Search Options </span>&nbsp; &nbsp;
                                    <span className="px-3 py-2 text-muted"> {this.state.setSearchFilters} &nbsp; <Link to={`/search?st=${this.state.searchBarText}&ob=date&ep=true&cp=true&bp=true&sp=true&ip=true&lp=true&np=true&mp=true&bbp=true&tstrict=0&gr=&grstrict=0&m=0&c=0&af=false&ff=false&rf=false&as=false&ct=&pn=1`}>Clear Options</Link></span>
                                </div>)
                                : null}

                            {this.state.totalPages > 1 ? <Pagination
                                totalPages={this.state.totalPages}
                                currentPage={this.state.page}
                                handlePageChange={this.handleResultPageChange}
                            /> : null}

                            <section className="mt-2 rounded d-flex" style={{ backgroundColor: "white", padding: "0px", marginBottom: "10px" }}>

                                <div className="table-responsive">
                                    <table className="table table-striped table-card">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-center text-primary">
                                                    <button type="button" onClick={this.orderById} style={{ cursor: "pointer", color: "black" }} className="btn btn-sm btn-link font-weight-bold">ID {this.sortChevron('id')}</button>
                                                </th>
                                                <th scope="col">&nbsp;</th>
                                                <th scope="col" style={{ textAlign: "left", cursor: "pointer", color: "black" }}>
                                                    <button type="button" className="btn btn-sm btn-link font-weight-bold" onClick={this.orderByName} style={{ cursor: "pointer", color: "black" }}>Campaign Name {this.sortChevron('name')}</button>
                                                </th>
                                                <th scope="col">CPA Type</th>
                                                <th scope="col" className="text-right">
                                                    <button type="button" className="btn btn-sm btn-link font-weight-bold" onClick={this.orderByRate} style={{ cursor: "pointer", color: "black" }}>CPA Rate {this.sortChevron('rate')}</button>
                                                </th>
                                                <th scope="col" className="text-right">
                                                    <button type="button" className="btn btn-sm btn-link font-weight-bold p-0 m-0" onClick={this.orderByEPC} style={{ cursor: "pointer", color: "black" }}>EPC {this.sortChevron('epc')}</button>
                                                </th>
                                                <th scope="col" className="text-right">Traffic</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.searchResults.map(result => {

                                                let rows = [];

                                                if (result["incentive_traffic"] === "Y") {
                                                    rows.push(<span className="fa fa-fw" data-tip="Incentive" key="Incentive"><FaMoneyBill size={14} color="grey" /></span>);
                                                }
                                                if (result["email_traffic"] === "Y") {
                                                    rows.push(<span className="fa fa-fw" data-tip="Email" key="Email"><FaEnvelope size={14} color="grey" /></span>);
                                                } if (result["contextual_traffic"] === "Y") {
                                                    rows.push(<span className="fa fa-fw" data-tip="Contextual" key="Contextual"><FaWindowRestore size={14} color="grey" /></span>);
                                                } if (result["mobile_traffic"] === "Y") {
                                                    rows.push(<span className="fa fa-fw" data-tip="Mobile Ads" key="Mobile Ads"><FaMobile size={14} color="grey" /></span>);
                                                } if (result["native_traffic"] === "Y") {
                                                    rows.push(<span className="fa fa-fw" data-tip="Native" key="Native"><FaImage size={14} color="grey" /></span>);
                                                } if (result["search_traffic"] === "Y") {
                                                    rows.push(<span className="fa fa-fw" data-tip="Search" key="Search"><FaSearch size={14} color="grey" /></span>);
                                                } if (result["display_traffic"] === "Y") {
                                                    rows.push(<span className="fa fa-fw" data-tip="Display" key="Display"><FaDesktop size={14} color="grey" /></span>);
                                                } if (result["social_media_traffic"] === "Y") {
                                                    rows.push(<span className="fa fa-fw" data-tip="Social" key="Social"><FaFacebook size={14} color="grey" /></span>);
                                                } if (result["brand_bid_traffic"] === "Y") {
                                                    rows.push(<span className="fa fa-fw" data-tip="Brand Bidding" key="Brand Bidding"><FaGavel size={14} color="grey" /></span>);
                                                }

                                                return (<SearchResults
                                                    key={result.campaign_id}
                                                    campaignId={result.campaign_id}
                                                    name={result.name}
                                                    affiliateRate={result.rate}
                                                    rateType={result.rate_type}
                                                    epc={result.epc}
                                                    allowedTrafficTypes={rows}
                                                    handleTotalPages={this.handleTotalPages}
                                                    thumbnail={result.thumbnail} />);
                                            })}

                                        </tbody>
                                    </table>
                                    <ReactTooltip />
                                </div>
                            </section>

                            {this.state.totalPages > 1 ? <Pagination
                                totalPages={this.state.totalPages}
                                currentPage={this.state.page}
                                handlePageChange={this.handleResultPageChange}
                            /> : null}

                        </div> :
                        <div>
                            {(this.state.filtersLoaded) ?
                                (<div className="d-flex rounded mt-2" style={{ background: "white" }}>
                                    <span className="font-weight-bold px-3 py-2" style={{ borderBottom: "3px solid #0089FE" }}>Search Options </span>&nbsp; &nbsp;
                                    <span className="px-3 py-2 text-muted"> {this.state.setSearchFilters} &nbsp; <Link to={`/search?st=${this.state.searchBarText}&ob=date&ep=true&cp=true&bp=true&sp=true&ip=true&lp=true&np=true&mp=true&bbp=true&tstrict=0&gr=&grstrict=0&m=0&c=0&af=false&ff=false&rf=false&as=false&ct=&pn=1`}>Clear Options</Link></span>
                                </div>)
                                : null}

                            <div className="alert alert-danger d-flex flex-row align-items-center" style={{ marginTop: "15px" }}>
                                <div style={{ marginBottom: "3px" }}><FaExclamationTriangle /></div>
                                <div style={{ marginLeft: "10px" }}> There are no campaigns that match your search criteria.</div>
                            </div>
                        </div>)
                    :
                    <div style={{ marginTop: '4rem' }}>
                        <Loading />
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default withRouter(Search);