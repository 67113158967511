import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Menu } from 'react-feather';
import { Link } from 'react-router-dom';

import './NavigationDropup.css';

class NavigationDropup extends Component {

    state = {
        dropdownOpen: false
    };

    toggle = () => {
        this.setState({
          dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {

        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                    tag="a"
                    onClick={this.toggle}
                    data-toggle="dropdown"
                    aria-expanded={this.state.dropdownOpen}
                    className="dropdown-toggle"
                    >
                    <Menu className="feather-icon" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" />                    
                </DropdownToggle>                
                <DropdownMenu>
                <DropdownItem header className="font-weight-bold text-dark">Navigation</DropdownItem>
                    <DropdownItem divider className="m-0" />
                    <Link className="dropdown-news-item dropdown-item" to="/" onClick={this.toggle}>Dashboard</Link>                    
                    <DropdownItem divider className="m-0"/>
                    <Link className="dropdown-news-item dropdown-item" to="/reports/earnings" onClick={this.toggle}>Reports</Link>
                    <DropdownItem divider className="m-0"/>
                    <Link className="dropdown-news-item dropdown-item" to="/browse/new" onClick={this.toggle}>Browse</Link>
                    <DropdownItem divider className="m-0"/>
                    <Link className="dropdown-news-item dropdown-item" to="/search" onClick={this.toggle}>Search</Link>
                    <DropdownItem divider className="m-0"/>
                    <Link className="dropdown-news-item dropdown-item" to="/maxmoney" onClick={this.toggle}>MaxMoney</Link>
                    <DropdownItem divider className="m-0"/>
                    <Link className="dropdown-news-item dropdown-item" to="/resources" onClick={this.toggle}>Resources</Link>
                    <DropdownItem divider className="m-0"/>
                    <DropdownItem tag="a" href="https://blog.maxbounty.com/" target="_blank" className="dropdown-news-item">Blog</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default NavigationDropup;