import React, { Component } from 'react';
import { ExternalLink } from 'react-feather';

class CampaignLandingPages extends Component {

    render() {
        return (
            <div className="card my-2">
                <div className="card-header card-header-no-border">
                    Landing Pages
                </div>
                <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            {this.props.landingPageSample !== "" &&
                                <tr>
                                    <td>Sample</td>
                                    <td align="right"><a target="_blank" rel="noopener noreferrer" href={this.props.landingPageSample}>Preview <ExternalLink size="14" /></a></td>
                                </tr>
                            }
                            {this.props.landingPages.map(lp => (
                                <tr key={lp.landing_page_id}>
                                    <td>{lp.name} {lp.default_lp === 'Y' && <span>(Default)</span>}</td>
                                    <td align="right"><a target="_blank" rel="noopener noreferrer" href={lp.landing_url}>Preview <ExternalLink size="14" /></a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default CampaignLandingPages;