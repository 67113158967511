import React, { Component } from 'react';
import { Prompt } from 'react-router';

import PhoneInput from 'react-phone-input-2';

class PhoneInformation extends Component {

    state = {
        editing: false,
        phone: this.props.phone,
        mobile: this.props.mobile,
        isFormHalfFilled: false,
        phoneTemp: this.props.phone,
        mobileTemp: this.props.mobile
    }

    setEditing = (e) => {
        e.preventDefault();
        this.setState({
            editing: !this.state.editing,
            phone: this.state.phone,
            mobile: this.state.mobile,
            phoneTemp: this.state.phone,
            mobileTemp: this.state.mobile,
            isFormHalfFilled: false
        });
    }

    phoneChangeHandler = (event, input) => {
        if (input === 'phone') {
            this.setState({ ...this.state, phoneTemp: event, isFormHalfFilled: true })
        } else if (input === 'mobile') {
            this.setState({ ...this.state, mobileTemp: event, isFormHalfFilled: true })
        }
    }

    validatePhone = async (mobile) => {
        const authToken = localStorage.getItem('mb-auth-token');

        return await fetch(`${process.env.REACT_APP_API_URL}/affiliate/validatePhone?m=${mobile}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authToken
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result["success"] === true) {
                    return result.isValid;
                } else {
                    return false;
                }
            })
            .catch(error => {
                console.log('error: ', error);
            });
    }


    saveSettings = async (e) => {
        e.preventDefault();
        e.persist();
        /* Do form submission */

        /* Edit the parent state for NAME. We do this because there are a few variables we store at the application level:
            -affiliate name
            -maxmoney
        */

        try {
            const validatePhone = await this.validatePhone(this.state.mobileTemp);
            if (!validatePhone) {
                this.props.showSystemMessage('error', 'Invalid mobile number');
                return;
            }
        } catch (error) {
            console.log('Error while validating phone number: ', error);
        }

        this.props.editMobile(this.state.mobileTemp);

        this.setState({
            // editing: !this.state.editing,
            isFormHalfFilled: false,
            mobile: this.state.mobileTemp,
            phone: this.state.phoneTemp
        }, () => {
            const authToken = localStorage.getItem("mb-auth-token");

            fetch(`${process.env.REACT_APP_API_URL}/affiliate/phoneDetails`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'x-app-token': authToken
                },
                body: JSON.stringify({
                    mobile: this.state.mobile,
                    phone: this.state.phone
                })
            })
                .then(res => res.json())
                .then(result => {
                    if (result["success"] === true) {
                        // If this is success then flag 121 should be good to be removed
                        this.setState({ editing: false }, () => {
                            return this.props.showSystemMessage("success", result.message)
                        });
                    } else if (result["success"] === false) {

                        // Restore the state to props 
                        this.setState({
                            mobile: this.props.mobile,
                            phone: this.props.phone,
                        }, () => { this.props.showSystemMessage("error", result.errors.message) })
                    }
                })
                .catch(error => {
                    this.props.showSystemMessage("error", error.message);
                });
        });
    }

    render() {
        return (
            <div className="card my-2 max-width-750 PersonalInfo">
                <Prompt
                    when={this.state.isFormHalfFilled}
                    message="Are you sure you want to leave? Changes you made may not be saved."
                />
                <form
                    onSubmit={this.saveSettings}>
                    <div className="card-header d-flex align-items-center">
                        <div className="flex-1">Phone Information</div>
                        {this.props.showEdit &&
                            <div className="text-right">
                                {this.state.editing ?
                                    <React.Fragment>
                                        <button className="btn btn-sm btn-outline-danger mr-2" type="button" onClick={this.setEditing}>Cancel</button>
                                        <button className="btn btn-success btn-sm" type="submit">Save</button>
                                    </React.Fragment>
                                    :
                                    <button className="btn btn-primary btn-sm" onClick={this.setEditing}>Edit</button>
                                }
                            </div>}
                    </div>
                    <div className="card-body p-0">
                        <table className="table table-card">
                            <tbody>
                                <tr>
                                    <td>Mobile Phone</td>
                                    <td style={{ width: "70%" }}>
                                        {this.state.editing === false ?
                                            <div>{this.state.mobile}</div>
                                            :
                                            <PhoneInput
                                                className="form-control"
                                                inputProps={{
                                                    required: true
                                                }}
                                                placeholder="Mobile Number"
                                                country={'us'}
                                                value={this.state.mobile}
                                                onChange={(e) => this.phoneChangeHandler(e, 'mobile')}
                                                containerStyle={{ width: '100%!important' }}
                                                countryCodeEditable={false}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>
                                        {this.state.editing === false ?
                                            <div>{this.state.phone}</div>
                                            :
                                            <PhoneInput
                                                className="form-control"
                                                placeholder="Phone Number"
                                                country={'us'}
                                                value={this.state.phone}
                                                onChange={(e) => this.phoneChangeHandler(e, 'phone')}
                                                countryCodeEditable={false}
                                            />
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </div>
        );
    }
}

export default PhoneInformation;