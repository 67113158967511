import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

class FormTerms extends Component {

    constructor(props) {
        super(props);
        this.formTerms = React.createRef();
        this.check1 = React.createRef();
        this.check2 = React.createRef();
        this.check3 = React.createRef();
    }

    state = {
        dataLoaded: true
    }

    validate = () => {

        /* Check required fields */
        const input_check1 = this.check1.current;
        const input_check2 = this.check2.current;
        const input_check3 = this.check3.current;

        /* Reset any previous custom validity settings to valid */

        if (input_check1.checkValidity() === false || input_check2.checkValidity() === false || input_check3.checkValidity() === false) {
            return false
        }
        else {
            return true
        }

    }

    saveAndContinue = async (e) => {

        e.preventDefault()

        if (this.validate()) {

            this.setState({
                dataLoaded: false
            })

            /* This is where the code goes to submit the form data. */
            const registrationData = { ...this.props.values, ...this.props.appData }

            await this.submitRegistration(registrationData);

        } else {
            /* Forces html5 form validation */
            const formTerms = this.formTerms.current;
            formTerms.reportValidity();
        }
    }

    submitRegistration = async (data) => {

        // We have to submit this data as formData because we now have file uploads for identification. So first we need to create the actual form data without
        // the files.
        const dataWithoutFiles = _.omit(data, ['idFront', 'idBack', 'firstname', 'lastname']);

        let formData = new FormData();

        for (var key in dataWithoutFiles) {
            formData.append(key, dataWithoutFiles[key]);
        }

        formData.append('idFront', data.idFront, `${data.name}_front`)
        formData.append('idBack', data.idBack, `${data.name}_back`)
        formData.append('firstName', data.firstname)
        formData.append('lastName', data.lastname)
        formData.append('name', `${data.firstname} ${data.lastname}`)

        await fetch(`${process.env.REACT_APP_API_URL}/register`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(
                async (result) => {

                    this.setState({
                        dataLoaded: true
                    })

                    if (result["success"] === true) {
                        // Proceed to the thank you page if everything went well

                        let name = this.props.values.firstname + " " + this.props.values.lastname;

                        await this.props.trackApplication(this.props.appData.appToken, this.props.values.email, 'Successful', this.props.values.company, name, this.props.values.address_1, this.props.values.address_2, this.props.values.city, this.props.values.state, this.props.values.country, this.props.values.zipcode, this.props.values.phone, this.props.values.mobile, this.props.values.im_handle, this.props.values.experience_description, this.props.values.experience_level)
                        this.props.nextStep();
                        return true;
                    } else {
                        this.props.showSystemMessage("error", result.errors.message);
                        await this.props.trackApplication(this.props.appData.appToken, this.props.values.email, result.errors.message)
                        return false;
                    }

                },
                async (error) => {
                    this.props.showSystemMessage("error", "Connection error.");
                    await this.props.trackApplication(this.props.appData.appToken, this.props.values.email, error.message)
                    this.setState({
                        dataLoaded: true
                    })
                    return false;
                }
            )
    }

    back = (e) => {
        e.preventDefault()
        this.props.prevStep();
    }

    render() {

        // const { values } = this.props

        return (
            <form ref={this.formTerms}>
                <div className="form-control mb-3">
                    <div className="custom-control">
                        <input
                            ref={this.check1}
                            type="checkbox"
                            className="custom-control-input"
                            id="check1"
                            required />
                        <label className="custom-control-label text-left" htmlFor="check1">Check this box if you agree that you will not share, trade, sell or otherwise transfer your account to a third party.</label>
                    </div>
                </div>
                <div className="form-control mb-3">
                    <div className="custom-control">
                        <input
                            ref={this.check2}
                            type="checkbox"
                            className="custom-control-input"
                            id="check2"
                            required />
                        <label className="custom-control-label text-left" htmlFor="check2">Check this box if you agree that you will not use freelancers, microjob sites, or guaranteed lead services to send traffic to MaxBounty.</label>
                    </div>
                </div>
                <div className="form-control mb-3">
                    <div className="custom-control">
                        <input
                            ref={this.check3}
                            type="checkbox"
                            className="custom-control-input"
                            id="check3"
                            required />
                        <label className="custom-control-label text-left" htmlFor="check3">Check this box if you agree to MaxBounty's affiliate <a href="/terms" target="_blank">terms and conditions</a>.</label>
                    </div>
                </div>
                <div className="d-flex">
                    <div className="pr-2 flex-1">
                        <button onClick={this.back} className="btn btn-lg btn-secondary btn-block">Back</button>
                    </div>
                    <div className="flex-1">
                        {this.state.dataLoaded ?
                            <button onClick={this.saveAndContinue} className="btn btn-lg btn-primary btn-block">Submit</button>
                            :
                            <button className="btn btn-lg btn-primary btn-block" disabled><FontAwesomeIcon icon="spinner" spin /></button>
                        }

                    </div>
                </div>
            </form>
        );
    }
}

export default FormTerms;